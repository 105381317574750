
const theme = (type) => ({
    colors:{
      light: '#FAFAFA',
      dark: '#202124',
      white: '#FFF',
      black: '#000',
      
    },
    blog: {
      background: (type==="light")? '#FAFAFA':'#080041',
      header:{
        title: {
          color: (type==="light")? '#060040':'#FFFFFF',
        },
        date: {
          color: (type==="light")? '#DD91AF':'#261FA8',
        }
      },
      note: {
        h4: {
          color: (type==="light")? '#000000':'#EA004C',
        },
        h3: {
          color: (type==="light")? '#000000':'#EA004C',
        },
      },
      fonts:{
        RobotoCondensed: 'Roboto Condensed',
        Roboto: 'Roboto',
        OpenSans: 'Open Sans',
        Mono: 'monospace'
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          "*, *::before, *::after": {
            boxSizing: "content-box",
          },
          body: {
            minWidth: '320px'
          }
        },
      },
    },
})

export default theme;