import React, { useEffect,useContext,Suspense,lazy } from 'react';
import ReactDOM from 'react-dom';
import GlobalData,{ StoreContext } from 'GlobalData';
import {actions} from 'constanst/pages';
import fetchData from 'Api';
import {
  BrowserRouter,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import {LoadingDialog} from 'components/Dialogs';
import AppWrapper from 'components/AppWrapper';
import PageWrapper from 'components/PageWrapper';
import TopBar from 'components/TopBar';
import Footer from 'components/Footer';
import 'utils/animate.css';
import NewsLetter from 'components/Newletter';
import { changeMetaData, removeFbChat } from 'utils';

const HomePage = lazy(() => import('pages/Home'));
const AboutPage = lazy(() => import('pages/About'));
const ClientsPage = lazy(() => import('pages/Clients'));
const ServicesPage = lazy(() => import('pages/Services'));
const ProjectsPage = lazy(() => import('pages/Projects'));
//const TeamPage = lazy(() => import('pages/Team'));
const ContactPage = lazy(() => import('pages/Contact'));
const WorkWithUsPage = lazy(() => import('pages/WorkWithUs'));
const ThankYouPage = lazy(() => import('pages/ThankYou'));
const PersonalInformation = lazy(() => import('pages/PersonalInformation'));
const BlogPage = lazy(() => import('pages/Blog'));

const checkMenuActive = (menu,pathname) =>{
  return menu.map(itm =>{
    return {...itm, active:(pathname===itm.to)}
  })
}

const GelaWeb = () =>{
  const { state,setGlobalState } = useContext(StoreContext);
  const location = useLocation();

  useEffect(()=>{
    let fbChatTimer;
    if(!state.lang){
      fetchData.get(`/server/geo.php`).then(rsp=>{
        if(window.dataLayer){
          window.dataLayer.push({'language': rsp});
        }
        if(rsp.ip){
          setGlobalState('CHANGE_LANG',rsp.language);
        }else{
          setGlobalState('CHANGE_LANG','en');
        }
      });
    }else{
      fetchData.json(`/assets/jsons/texts_${state.lang}.json?v${window.json_cache}`).then(rsp=>{
        if (rsp) {
          rsp.menu=checkMenuActive(rsp.menu,location.pathname);
          setGlobalState(actions.PAGES_DATA,{...rsp, mainData:true});
          changeMetaData({ title: rsp.title,description: rsp.description, keywords: rsp.keywords });
        } else {
          console.log("ERROR JSON!", rsp);
        }
      }).catch((e) => {
        console.log("ERROR JSON!", e);
      });

      removeFbChat(document.getElementsByClassName("fb-customerchat"), state.lang);
      removeFbChat(document.getElementsByClassName("fb_dialog"), state.lang);
      if(state.lang==="es"){
        fbChatTimer = setTimeout(() => {
          var script = document.createElement("script");
          script.innerHTML = "(function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s); js.id = id;js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));";
          document.body.appendChild(script);
        }, 8000);
      }
    }
    return () =>{
      clearTimeout(fbChatTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.lang]);

  useEffect(()=>{
    setGlobalState(actions.ACTUAL_PAGE,checkMenuActive(state.menu,location.pathname));
    if(window.dataLayer){
      window.dataLayer.push({'page': location.pathname});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.pathname]);

  if(state.mainData){
    return (<AppWrapper {...state.settings.container}>
      {state.settings.topBar.show && <TopBar {...state.settings.topBar} />}
      <PageWrapper>
        <Suspense fallback={<LoadingDialog open={true} />}>
          <Switch>
            <Route path={['/home','/reel','/inicio']} component={HomePage}/>
            <Route path={['/agency','/agencia']} component={AboutPage}/>
            <Route path={['/clients','/clientes']} component={ClientsPage}/>
            <Route path={['/services','/servicios','/services/:srv','/servicios/:srv']} exact component={ServicesPage}/>
            <Route path={['/projects','/campaigns','/campañas','/:lng/campaigns','/:lng/campaigns/:pid','/campaigns/:pid','/campañas/:pid']} exact component={ProjectsPage}/>
            {/*state.lang === "es" && <Route path={['/team','/equipo']} component={TeamPage}/>*/}
            <Route path={['/contact','/contacto']} component={ContactPage}/>
            {state.lang === "es" && <Route path={['/jobs','/careers','/bolsa-de-trabajo']} component={WorkWithUsPage}/>}
            <Route path={['/thankyou/:type','/gracias/:type']} exact component={ThankYouPage}/>
            <Route path={['/personal-information','/informacion-personal']} component={PersonalInformation} />
            <Route path={['/blog','/:lng/blog','/blog/:bid','/:lng/blog/:bid']} exact component={BlogPage}/>
            <Route component={AboutPage}/>
          </Switch>
        </Suspense>
      </PageWrapper>
      <NewsLetter texts={state.newsLetter} lang={state.lang} />
      <Footer {...state.settings.footer} footer={state.footer} menu={state.menu} lang={state.lang} />
    </AppWrapper>)
  }
  return <LoadingDialog open={true} />
}

ReactDOM.render(
  <GlobalData>
    <BrowserRouter>
      <GelaWeb />
    </BrowserRouter>
  </GlobalData>,
  document.getElementById('root')
);
